import { emitter } from '@/utils/emitter'
import Cookies from 'js-cookie'
import { phoneCodes } from '@/utils/phoneCodes'
import { uuid } from '@/utils/math'

declare const window: any

export class EventsMediator {
  static instance: EventsMediator

  private constructor() {
    this.subscribe()
  }

  static init() {
    if (this.instance) return this.instance

    this.instance = new EventsMediator()
    return this.instance
  }

  subscribe() {
    emitter.on('3d-carousel-swiped', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: '3d-carousel-swiped',
        payload: payload
      })
    })

    emitter.on('speak-with-coach-cta-clicked', () => {
      window.dataLayer.push({
        event: 'speak-with-coach-cta-clicked',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'speak-with-coach-cta-clicked',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('speak-with-coach-cta-modal-closed', () => {
      window.dataLayer.push({
        event: 'speak-with-coach-cta-modal-closed',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'speak-with-coach-cta-modal-closed',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('faq-item-opened', (title) => {
      window.dataLayer.push({
        event: 'faq-item-opened',
        title: title,
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'faq-item-opened',
        payload: {
          faqItemTitle: title,
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('faq-item-closed', (title) => {
      window.dataLayer.push({
        event: 'faq-item-closed',
        title: title,
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'faq-item-closed',
        payload: {
          faqItemTitle: title,
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('contact-form-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'contact-form-submitted',
        payload: payload
      })

      window._paq.push(['FormAnalytics::trackFormConversion', '', 'contact-form'])
    })

    emitter.on('speak-with-coach-cta-phone-number-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      // @ts-ignore
      const phoneCode = phoneCodes[payload.phoneCountryCode]

      payload.phone = phoneCode + payload.phone
      payload.phone = payload.phone[0] !== '+' ? '+' + payload.phone : payload.phone

      payload.event_id = uuid()

      window.dataLayer.push({
        event: 'speak-with-coach-cta-phone-number-submitted',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'speak-with-coach-cta-phone-number-submitted',
        payload: payload
      })
    })

    emitter.on('speak-with-coach-cta-email-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')
      payload.event_id = uuid()

      window.dataLayer.push({
        event: 'speak-with-coach-cta-email-submitted',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'speak-with-coach-cta-email-submitted',
        payload: payload
      })
    })

    emitter.on('checkout-testimonials-slide-change', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'checkout-testimonials-slide-change',
        payload: payload
      })
    })

    emitter.on('checkout-testimonial-clicked', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'checkout-testimonial-clicked',
        payload: payload
      })
    })

    emitter.on('home-page-testimonial-clicked', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'home-page-testimonial-clicked',
        payload: payload
      })
    })

    emitter.on('checkout-plans-step-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')
      payload.event_id = uuid()

      window.dataLayer.push({
        event: 'checkout-plans-step-submitted',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._paq = window._paq || []
      window._paq.push(['trackGoal', 7])
      window._mtm.push({
        event: 'checkout-plans-step-submitted',
        payload: payload
      })

      let sku: string, name: string, price: number

      function populatePayload() {
        switch (payload.plan) {
        case 'monthly':
          sku = '1'
          name = 'Monthly Subscription'
          price = payload.monthlyPrice
          return
        case 'annual':
          sku = '2'
          name = 'Annual Subscription'
          price = payload.annualPrice
          return
        case 'eliteMonthly':
          sku = '3'
          name = 'Monthly Elite Subscription'
          price = payload.monthlyElitePrice
          return
        case 'eliteAnnual':
          sku = '4'
          name = 'Annual Elite Subscription'
          price = payload.annualElitePrice
          return
        }
      }

      populatePayload()

      window._paq.push([function (this: any) {
        if (sku === '1') {
          this.removeEcommerceItem(2)
          this.removeEcommerceItem(3)
          this.removeEcommerceItem(4)
        } else if (sku === '2') {
          this.removeEcommerceItem(1)
          this.removeEcommerceItem(3)
          this.removeEcommerceItem(4)
        } else if (sku === '3') {
          this.removeEcommerceItem(1)
          this.removeEcommerceItem(2)
          this.removeEcommerceItem(4)
        } else if (sku === '4') {
          this.removeEcommerceItem(1)
          this.removeEcommerceItem(2)
          this.removeEcommerceItem(3)
        }

        this.trackEcommerceCartUpdate()

        window._paq.push(['addEcommerceItem',
          sku,
          name,
          ['Plans'],
          price
        ])
      }])

      window._paq.push(['FormAnalytics::trackFormConversion', '', 'plans-step'])
    })

    emitter.on('homepage-plans-section-submitted', (payload) => {
      return
    })

    emitter.on('checkout-plans-viewed', () => {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-plans-viewed',
      })

      window._paq.push(['setEcommerceView',
        false,
        false,
        'Plans'
      ])
    })

    emitter.on('checkout-phone-number-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')
      payload.event_id = uuid()

      // @ts-ignore
      const phoneCode = phoneCodes[payload.phoneCountryCode]

      payload.phone = phoneCode + payload.phone
      payload.phone = payload.phone[0] !== '+' ? '+' + payload.phone : payload.phone

      window.dataLayer.push({
        event: 'checkout-phone-number-submitted',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-phone-number-submitted',
        payload: payload
      })
    })

    emitter.on('checkout-phone-verification-ask-again-clicked', (payload) => {
      const inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000)
      Cookies.set('sms-sent-again','true', { expires: inOneMinute })

      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'checkout-phone-verification-ask-again-clicked',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-phone-verification-ask-again-clicked',
        payload: payload
      })
    })

    emitter.on('speak-with-coach-cta-ask-again-clicked', (payload) => {
      const inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000)
      Cookies.set('sms-sent-again','true', { expires: inOneMinute })

      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'speak-with-coach-cta-ask-again-clicked',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'speak-with-coach-cta-ask-again-clicked',
        payload: payload
      })
    })

    emitter.on('checkout-information-step-submitted', (payload) => {
      const copy = JSON.parse(JSON.stringify(payload))
      copy.hubspotutk = Cookies.get('hubspotutk')
      copy.event_id = uuid()
      // @ts-ignore
      const phoneCode = phoneCodes[copy.phoneCountryCode]

      copy.phone = phoneCode + copy.phone
      copy.phone = copy.phone[0] !== '+' ? '+' + copy.phone : copy.phone

      window.dataLayer.push({
        event: 'checkout-information-step-submitted',
        payload: copy
      })

      window._mtm = window._mtm || []
      window._paq = window._paq || []
      window._paq.push(['trackGoal', 6])
      window._mtm.push({
        event: 'checkout-information-step-submitted',
        payload: copy
      })

      window._paq.push(['FormAnalytics::trackFormConversion', '', 'information-step'])
    })

    emitter.on('punta-mita-form-submitted', (payload) => {
      window.dataLayer.push({
        event: 'punta-mita-form-submitted',
        payload: payload
      })
    })

    emitter.on('checkout-payment-step-submitted', (payload) => {
      const copy = JSON.parse(JSON.stringify(payload))
      delete copy.paymentIntentId

      copy.hubspotutk = Cookies.get('hubspotutk')
      copy.event_id = uuid()

      // @ts-ignore
      const phoneCode = phoneCodes[copy.phoneCountryCode]

      copy.phone = phoneCode + copy.phone
      copy.phone = copy.phone[0] !== '+' ? '+' + copy.phone : copy.phone

      window.dataLayer.push({
        event: 'checkout-payment-step-submitted',
        payload: copy
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-new-customer',
        payload: copy
      })

      window._paq = window._paq || []
      window._paq.push(['trackGoal', 1, payload.price])
      window._paq.push(['trackEcommerceOrder',
        uuid(),
        payload.price,
        false,
        false,
        false,
        false
      ])

      window._paq.push(['FormAnalytics::trackFormConversion', '', 'billing-step'])
    })

    emitter.on('purchase-course', (payload) => {
      const copy = JSON.parse(JSON.stringify(payload))
      delete copy.paymentIntentId

      copy.hubspotutk = Cookies.get('hubspotutk')
      copy.event_id = uuid()

      // @ts-ignore
      const phoneCode = phoneCodes[copy.phoneCountryCode]
      copy.phone = phoneCode + copy.phone
      copy.phone = copy.phone[0] !== '+' ? '+' + copy.phone : copy.phone
      copy.course_name = 'scientific-productivity-course'

      window.dataLayer.push({
        event: 'purchase-scientific-productivity-course',
        payload: copy
      })

      window._paq = window._paq || []
      window._paq.push(['trackGoal', 1, payload.price])
      window._paq.push(['trackEcommerceOrder',
        uuid(),
        payload.price,
        false,
        false,
        false,
        false
      ])

      window._paq.push(['FormAnalytics::trackFormConversion', '', 'billing-step'])
    })

    emitter.on('checkout-payment-declined', (payload) => {
      window.dataLayer.push({
        event: 'checkout-payment-declined',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-payment-declined',
        payload: payload
      })
    })

    emitter.on('init-checkout', () => {
      if (!window.dataLayer) {
        window.dataLayer = []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      }

      window.dataLayer.push({
        event: 'init-checkout',
        payload: {
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })

      window._paq = window._paq || []
      window._paq.push(['trackGoal', 8])
      window._mtm = window._mtm || []
      window._mtm.push({'event': 'checkout-initiated'})
    })

    emitter.on('checkout-payment-error', (payload) => {
      window.dataLayer.push({
        event: 'checkout-payment-error',
        payload: payload
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'checkout-payment-error',
        payload: payload
      })
    })

    emitter.on('trustpilot-clicked', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'trustpilot-clicked',
        payload: payload
      })
    })

    emitter.on('apply-form-submitted', () => {
      //
    })

    emitter.on('free-call-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'free-call-information-step-submitted',
        payload: payload
      })

      if (!payload.skip_free_call_requested_event && !payload.isNonqualifiedLead) {
        window.dataLayer.push({
          event: 'free-call-requested',
          payload: payload
        })
      }

      window._paq = window._paq || []
      window._paq.push(['trackGoal', process.env.NEXT_PUBLIC_SITE === 'https://www.commitaction.com' ? 13 : 10])

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'free-call-information-step-submitted',
        payload: payload
      })
    })

    emitter.on('schedule-call-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'schedule-call-submitted',
        payload: payload
      })

      // window._paq = window._paq || []
      // window._paq.push(['trackGoal', process.env.NEXT_PUBLIC_SITE === 'https://www.commitaction.com' ? 13 : 10])
    })

    emitter.on('free-call-information-step-submitted-rich-person', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'free-call-information-step-submitted-rich-person',
        payload: payload
      })
    })

    emitter.on('free-call-requested-rich-person', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'free-call-requested-rich-person',
        payload: payload
      })
    })

    emitter.on('coach-booking-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'coach-booking-submitted',
        payload: payload
      })
    })

    emitter.on('schedule-form-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')

      window.dataLayer.push({
        event: 'schedule-form-submitted',
        payload: payload
      })
    })

    emitter.on('hard-science-offcanvas-opened', () => {
      if (!window.dataLayer) {
        window.dataLayer = []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      }

      window.dataLayer.push({
        event: 'hard-science-offcanvas-opened',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'hard-science-offcanvas-opened',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('hard-science-offcanvas-closed', () => {
      window.dataLayer.push({
        event: 'hard-science-offcanvas-closed',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'hard-science-offcanvas-closed',
        payload: {
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('discount-badge-closed', () => {
      window.dataLayer.push({
        event: 'discount-badge-closed'
      })
    })

    emitter.on('discount-badge-button-clicked', () => {
      window.dataLayer.push({
        event: 'discount-badge-button-clicked'
      })
    })

    emitter.on('cookie-consent-accept-all', () => {
      if (!window.dataLayer) {
        window.dataLayer = []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      }

      Cookies.set('cookie-consent', JSON.stringify({
        ad_storage: 'granted',
        analytics_storage: 'granted',
        personalization_storage: 'granted'
      }), { domain: '.' + process.env.NEXT_PUBLIC_RELATIVE_URL, expires: 183 })

      window.dataLayer.push({
        event: 'cookie-consent-accept-all'
      })
    })

    emitter.on('cookie-consent-reject-all', () => {
      Cookies.set('cookie-consent', JSON.stringify({
        ad_storage: 'denied',
        analytics_storage: 'denied',
        personalization_storage: 'denied'
      }), { domain: '.' + process.env.NEXT_PUBLIC_RELATIVE_URL, expires: 183 })

      window.dataLayer.push({
        event: 'cookie-consent-reject-all'
      })
    })

    emitter.on('cookie-consent-configure', (payload) => {
      Cookies.set('cookie-consent', JSON.stringify(payload), { domain: '.' + process.env.NEXT_PUBLIC_RELATIVE_URL, expires: 183 })

      window.dataLayer.push({
        event: 'cookie-consent-configure',
        ...payload
      })
    })

    emitter.on('cookie-consent-set-from-cookies', (payload) => {
      if (!window.dataLayer) {
        window.dataLayer = []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      }

      window.dataLayer.push({
        event: 'cookie-consent-set-from-cookies',
        ...payload
      })
    })

    emitter.on('video-training-email-submitted', (payload) => {
      payload.hubspotutk = Cookies.get('hubspotutk')
      payload.event_id = uuid()

      window.dataLayer.push({
        event: 'video-training-email-submitted',
        payload: payload
      })
    })

    emitter.on('video-training-text-a-coach-clicked', () => {
      window.dataLayer.push({
        event: 'video-training-text-a-coach-clicked'
      })
    })

    emitter.on('training-video-started', (title) => {
      window.dataLayer.push({
        event: 'training-video-started',
        payload: {
          videoTitle: title,
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('training-video-ended', (title) => {
      window.dataLayer.push({
        event: 'training-video-ended',
        payload: {
          videoTitle: title,
          hubspotutk: Cookies.get('hubspotutk')
        }
      })
    })

    emitter.on('youtube-testimonial-clicked', () => {
      window.dataLayer.push({
        event: 'youtube-testimonial-clicked'
      })
    })

    emitter.on('guarantee-info-opened', () => {
      window.dataLayer.push({
        event: 'guarantee-info-opened'
      })
    })

    emitter.on('free-call-time-submitted', () => {
      window._paq = window._paq || []
      window._paq.push(['trackGoal', process.env.NEXT_PUBLIC_SITE === 'https://www.commitaction.com' ? 12 : 9])

      window.dataLayer.push({
        event: 'free-call-time-submitted',
        payload: {
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'free-call-time-submitted',
        payload: {
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })
    })

    emitter.on('call-questions-form-submitted', (payload) => {
      window.dataLayer.push({
        event: 'call-questions-form-submitted',
        payload: {
          ...payload,
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })

      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'call-questions-form-submitted',
        payload: {
          ...payload,
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })
    })

    emitter.on('exit-intent-modal-shown', () => {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'exit-intent-modal-shown'
      })
    })

    emitter.on('exit-intent-modal-closed', () => {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'exit-intent-modal-closed'
      })
    })

    emitter.on('exit-intent-modal-feedback-submitted', (payload) => {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'exit-intent-modal-feedback-submitted',
        payload: payload
      })
    })

    emitter.on('exit-intent-modal-email-submitted', (payload) => {
      window.dataLayer.push({
        event: 'exit-intent-modal-email-submitted',
        payload: {
          ...payload,
          hubspotutk: Cookies.get('hubspotutk'),
          event_id: uuid()
        }
      })
    })

    emitter.on('quiz-form-submitted', (payload) => {
      // @ts-ignore
      const phoneCode = phoneCodes[payload.phoneCountryCode]

      payload.phone = phoneCode + payload.phone
      payload.phone = payload.phone[0] !== '+' ? '+' + payload.phone : payload.phone

      const data = {
        email: payload.email,
        phone: payload.phone,
        self_employed: payload.personalAnswers[0],
        employees_manage: payload.personalAnswers[1],
        current_monthly_revenue: payload.personalAnswers[2],
        agreeableness: payload.result[0],
        conscientiousness: payload.result[1],
        extraversion: payload.result[2],
        neuroticism: payload.result[3],
        openness_to_experience: payload.result[4],
        compassion: payload.innerResult[0][0],
        politeness: payload.innerResult[0][1],
        industriousness: payload.innerResult[1][0],
        orderliness: payload.innerResult[1][1],
        enthusiasm: payload.innerResult[2][0],
        assertiveness: payload.innerResult[2][1],
        volatility: payload.innerResult[3][1],
        withdrawal: payload.innerResult[3][0],
        intellect: payload.innerResult[4][0],
        openness: payload.innerResult[4][1],
        event_id: uuid()
      }

      window.dataLayer.push({
        event: 'quiz-form-submitted',
        payload: data
      })
    })

    emitter.on('quiz-started', () => {
      window.dataLayer.push({
        event: 'quiz-started',
        payload: {
          event_id: uuid()
        }
      })
    })
  }
}
