const initialState = {
  activeStep: 1,
  firstName: '',
  lastName: '',
  emailRecheck: false,
  emailIsValidating: false,
  email: '',
  phone: '',
  phoneFull: '',
  phoneCountryCode: '',
  isPhoneVerified: false,
  phoneVerificationSkipped: false,
  plan: 'monthly',
  planSetted: false,
  prices: {
    annual: 5520,
    monthly: 575,
    trial: 49,
    'scientific-productivity-course-2024-usd': 97
  },
  coupon: {
    loading: true,
    available: false,
    value: null,
    annualDiscount: null,
    monthlyDiscount: null,
    discount: null,
    finalPrice: null,
    initialPrice: null,
    meta: {}
  },
  referral: {
    loading: true,
    loaded: false,
    gift: false,
    available: false,
    referralId: null,
    referralToken: null,
    referralName: null,
    discount: {
      monthly: 0,
      annual: 0
    }
  },
  address1: '',
  address2: '',
  city: '',
  state: { value: 'NY', label: 'New York' },
  region: '',
  zip: '',
  country: { value: 'US', label: 'United States' },
  touchedSteps: {
    1: true,
    2: false,
    3: false
  },
  progress: 0.3,
  chargebeeLoaded: false,
  fastSignupUsed: false,
  checkoutType: 'coaching'
}

interface Action {
  type: string,
  payload: any
}

export const checkoutReducer = (currentState = initialState, action: Action) => {
  switch(action.type) {
  case 'SET_STEP_ONE_DATA':
    return {
      ...currentState,
      ...action.payload
    }
  case 'SET_PHONE_IS_VERIFIED':
    return {
      ...currentState,
      isPhoneVerified: action.payload
    }
  case 'SET_PHONE_VERIFICATION_SKIPPED':
    return {
      ...currentState,
      phoneVerificationSkipped: action.payload
    }
  case 'SET_PHONE_DATA':
    return {
      ...currentState,
      phone: action.payload.phone,
      phoneCountryCode: action.payload.phoneCountryCode
    }
  case 'SET_PHONE_FULL_DATA':
    return {
      ...currentState,
      phoneFull: action.payload,
    }
  case 'SET_TOUCHED_STEP':
    currentState.touchedSteps[action.payload as 1 | 2 | 3] = true
    return {
      ...currentState
    }
  case 'SET_STEP_TWO_DATA':
    return {
      ...currentState,
      plan: action.payload
    }
  case 'SET_STEP_TWO_SETTED':
    return {
      ...currentState,
      planSetted: action.payload
    }
  case 'SET_STEP_THREE_DATA':
    return {
      ...currentState,
      address1: action.payload.address1,
      address2: action.payload.address2,
      city: action.payload.city,
      zip: action.payload.zip
    }
  case 'SET_ACTIVE_STEP':
    return {
      ...currentState,
      activeStep: action.payload
    }
  case 'SET_CHARGEBEE_LOADED':
    return {
      ...currentState,
      chargebeeLoaded: action.payload
    }
  case 'SET_PRICES':
    return {
      ...currentState,
      prices: { ...currentState.prices, ...action.payload }
    }
  case 'SET_COUNTRY':
    return {
      ...currentState,
      country: action.payload
    }
  case 'SET_STATE':
    return {
      ...currentState,
      state: action.payload
    }
  case 'SET_REGION':
    return {
      ...currentState,
      region: action.payload
    }
  case 'SET_REFERRAL':
    return {
      ...currentState,
      referral: {
        ...currentState.referral,
        loading: action.payload.loading,
        loaded: action.payload.loaded,
        gift: action.payload.gift,
        available: action.payload.available,
        referralId: action.payload.referralId,
        referralToken: action.payload.referralToken,
        referralName: action.payload.referralName,
        discount: {
          monthly: action.payload.discount.monthly,
          annual: action.payload.discount.annual
        }
      }
    }
  case 'SET_COUPON':
    return {
      ...currentState,
      coupon: {
        ...currentState.coupon,
        loading: action.payload.loading,
        available: action.payload.available,
        value: action.payload.value,
        annualDiscount: action.payload.annualDiscount,
        monthlyDiscount: action.payload.monthlyDiscount,
        finalPrice: action.payload.finalPrice,
        initialPrice: action.payload.initialPrice,
        discount: action.payload.discount,
        meta: action.payload.meta
      }
    }
  case 'SET_EMAIL_IS_VALIDATING':
    return {
      ...currentState,
      emailIsValidating: action.payload
    }
  case 'SET_PROGRESS':
    return {
      ...currentState,
      progress: action.payload
    }
  case 'SET_FAST_SIGNUP_USED':
    return {
      ...currentState,
      fastSignupUsed: true
    }
  case 'SET_EMAIL_RECHECK_TRUE':
    return {
      ...currentState,
      emailRecheck: true
    }
  case 'SET_EMAIL_RECHECK_FALSE':
    return {
      ...currentState,
      emailRecheck: false
    }
  case 'SET_CHECKOUT_TYPE':
    return {
      ...currentState,
      checkoutType: action.payload
    }
  default:
    return currentState
  }
}
