import { animateScroll } from '@/utils/animation'

declare const document: any
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = {37: 1, 38: 1, 39: 1, 40: 1}

function preventDefault(e: any) {
  e.preventDefault()
}

function preventDefaultForScrollKeys(e: any) {
  // @ts-ignore
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

// modern Chrome requires { passive: false } when adding event
const supportsPassive = true

const wheelOpt = supportsPassive ? { passive: false } : false

export function disableScroll() {
  const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false)
}

export function scrollToHash(hash: string) {
  location.hash = ''
  location.hash = '#' + hash
}

export function enableScroll() {
  const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  // @ts-ignore
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
  // @ts-ignore
  window.removeEventListener('touchmove', preventDefault, wheelOpt)
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
}

const getElementPosition = (element: HTMLElement) => element.offsetTop

export const scrollTo = (id: string, duration = 2000) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = window.scrollY

  const element = document.getElementById(id)

  if (!element) {
    return
  }

  animateScroll(
    getElementPosition(element),
    initialPosition,
    duration
  )
}

export const scrollToAnimationLess = (id: string) => {
  // the position of the scroll bar before the user clicks the button
  // const initialPosition = window.scrollY

  const element = document.getElementById(id)

  if (!element) {
    return
  }

  window.scrollTo(0, getElementPosition(element))
}
